import React from "react";
import "./AuditRows.css";
import {
  extractFileName,
  extractFileNameArray,
  extractUserName,
} from "../../utils/util";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";

const getColor = (status) => {
  console.log(status, "statuscolor:::");
  const red = [
    "Flagged for Review",
    "Reviewed,no change",
    "Reviewed,query required",
  ]; // #F8698521;
  const blue = [
    "Reviewed,reclaimed",
    "Reviewed,rebilled",
    "Reviewed,query required",
  ]; // #00395B
  const gray = ["Reviewed,query sent", "Reviewed,recoded"]; // #344054;
  if (status[0] === "Failed") {
    return "red";
  } //#E4F5FF
  else if (status[0] === "Flagged for Review") {
    return "red";
  } //#E4F5FF
  else if (status[0] === "passed") {
    return "passed";
  } //#feecef;
  else if (status[0] === "Reviewed,no change") {
    return "red";
  } //#E4F5FF

  if (red.includes(status)) return "red";
  if (blue.includes(status)) return "blue";
  if (gray.includes(status)) return "gray";
};

const AuditRowCard = ({ handleRowClick, row, isActive,handleCheckboxChange,checkedIds }) => {
  console.log('CARD:::',row?.audit_status)
  const {name,episode_identifier} = row;
  const allLoadIds = checkedIds?.map((item)=>{
    return item.episode_identifier
  });
  console.log("LOAD:::",allLoadIds)
  return (
    <>
      <tr
        className={`auditrow ${isActive && "active"}`}
        onClick={() => handleRowClick(row)}
      >
        <td>
          <input type="checkbox" onClick={(e)=>handleCheckboxChange(e,name,episode_identifier)} defaultChecked={allLoadIds?.includes(episode_identifier)}/>
        </td>
        <td>
          {" "}
          {row?.priority_value?.map((eachPriority,index) => {
            return <span key={index} className={`audit-count p${eachPriority}`} style={{marginRight:'5px'}}>
              P{eachPriority}
            </span>;
          })}
        </td>
        <td>{row?.file_name?.map((eachFileName,index)=>{
           return (<span key={index}>{extractFileName(eachFileName)}
            {index<row.file_name.length-1&&', '}</span>)
        })}</td>
        <td>{extractUserName(row.name)}</td>
        <td>{row.date}</td>
        <td>{row?.audit_rule_short_description?.map((eachDesc,index)=>{
           return (<span key={index}>
                   {eachDesc}
                   {index<row.audit_rule_short_description.length-1&&', '}</span>
           )
        })}</td>
        <td>
         {
              <div className={`audit-status-btn ${getColor(row.audit_status)}`}>
              
                <span style={{marginRight:'5px'}}>{row.audit_status}</span>
              </div>
           
          }
        
        </td>
        <td>{episode_identifier}</td>
      </tr>
    </>
  );
};

function AuditRows({
  handleRowClick,
  auditRows,
  selectedIndex,
  hasMore,
  loadMore,
  isRowsLoading,
  handleCheckboxChange,
  checkedIds,
  handleAllCheckboxes,
  allChecked
}) {
  console.log("AUDIT::::",auditRows)
  return (
    <>
      <InfiniteScroll
        className=" audits-main"
        hasData={hasMore}
        loadMore={() => loadMore()}
        isLoading={isRowsLoading}
      >
        <table id="audits-main" className="">
          <thead>
            <tr>
              <th>
                <input type="checkbox" onClick={handleAllCheckboxes} checked={allChecked}/>
              </th>
              <th>Select All</th>
              <th>Document Name</th>
              <th>User Name</th>
              <th>Date</th>
              <th>Description</th>
              <th>Status</th>
              <th>Episode Identifier</th>
            </tr>
          </thead>
          <tbody>
            {auditRows.map((row, idx) => (
              // console.log('rowxjdfjfdj',{row,idx})
              <AuditRowCard
                key={row?.file_name}
                row={row}
                handleRowClick={handleRowClick}
                isActive={selectedIndex === idx}
                handleCheckboxChange={handleCheckboxChange}
                checkedIds={checkedIds}
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
}

export default AuditRows;