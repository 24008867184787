import axios from "axios";
import axiosInstance from "../../../axiosInterceptor";

export const updateMessageLikeStatus = async (payload) => {
  const { session_token, is_liked, message_id,user_comment_on_message_response = "" } = payload;
  const messageLikeUpdateURL =
    "https://wdehsi2mo9.execute-api.ap-southeast-2.amazonaws.com/llm_is_liked_v2";
  return await axiosInstance
    .post(messageLikeUpdateURL, {
      body: {
        session_token,
        message_id,
        is_liked,
        user_comment_on_message_response
      },
    })
    .then((res) => {
      const result = JSON.parse(res.data.body);
      return result;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const recommendationUrlv2 =
  "https://t8w233foph.execute-api.us-west-2.amazonaws.com/pallas-ai-gen-ai-lambda-func-v3/";
export const getRecommendationData = async (prompt) => {
  const url = `${recommendationUrlv2}?prompt=${encodeURIComponent(prompt)}`;
  return await axiosInstance
    .get(url)
    .then((res) => {
      const content = res.data.pages;
      return content;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getChatConversation = async (id, page_number=1) => {
  const endPoint =
    "https://8q3ops3g30.execute-api.ap-southeast-2.amazonaws.com/llm_get_messages_v2";
  const accessToken = sessionStorage.getItem("accessToken");
  return await axiosInstance
    .post(endPoint, {
      body: {
        session_token: accessToken,
        conversation_id: id,
        page_number,
      },
    })
    .then(({ data }) => {
      const messages = JSON.parse(data.body).message;
      console.log("MESSAGE::52", messages, data)
      if (messages === "No  messages") {
        return {error: messages}
      }
      if (messages.conversations) {
        messages.conversations.forEach((message) => {
          message.answer = JSON.parse(message.answer);
        });
        const isVersion2 =
          typeof messages.conversations[0].answer.answer === "object";
        return { conversations: messages.conversations, isVersion2 };
      }
    })
    .catch((err) => {
      console.log("err::::::", err)
      throw new Error(err.message);
    });
};
export const fetchArchiveChat = async () => {
  const archiveApiEndPoint =
    "https://nplbsn4u2b.execute-api.ap-southeast-2.amazonaws.com/post_conversation_test/";
  const accessToken = sessionStorage.getItem("accessToken");
  const archivePayload = {
    body: {
      session_token: accessToken,
      archive: true,
    },
  };
  return await axiosInstance
    .post(archiveApiEndPoint, archivePayload)
    .then((response) => {
      const { body } = response.data;
      const archivedConversations = JSON.parse(body).message;
      return archivedConversations;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getReferencesWithPageNumber = async (
  content,
  s3Location,
  isV1 = false
) => {
  const url =
    "https://z328a2phy7.execute-api.ap-southeast-2.amazonaws.com/chat_references_optimised_v1";
  let payload;
  if (isV1) {
    payload = {
      content_list: content,
    };
  } else {
    payload = {
      content_list: [
        {
          content: content,
          s3_location: [s3Location],
        },
      ],
    };
  }

  return await axiosInstance
    .post(url, { body: payload })
    .then((res) => {
      console.log("RES:::", res);
      const { body, errorMessage } = res?.data;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      console.log("PAGE_NUMBER::::", JSON.parse(body));
      return JSON.parse(body);
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
export const getConvo = async (pageNumber=1) => {
  const getapiEndpoint =
    "https://qalwtsz7r3.execute-api.ap-southeast-2.amazonaws.com/llm_get_conversation_v2";
  const accessToken = sessionStorage.getItem("accessToken");
  const getpayload = {
    body: {
      session_token: accessToken,
      archive: false,
      page_number:pageNumber
    },
  };
  return await axiosInstance
    .post(getapiEndpoint, getpayload)
    .then((response) => {
      const { body } = response.data;
      const messages = JSON.parse(body).message;
      return messages.conversations;
    })
    .catch((error) => {
      console.error("Error in Get request:", error);
      throw new Error(error.message);
    });
};
