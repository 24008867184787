import axios from "axios";
import axiosInstance from "../../../axiosInterceptor";
import { debounce, reject } from "lodash";
export const getAuditRows = async (pageNo = 1, filters) => {
  let payload = "";
  console.log("PAYLOAD::::", filters);
  // Object.entries(filters).map((filter) => {
  //   if (!filter[1]) {
  //     console.log("PAYLOAD::::1",filter[1],filter)
  //     return;
  //   }
  //   if (typeof filter[1] === "string") {
  //     console.log("PAYLOAD::::2",filter[1])
  //     payload += `&${filter[0]}=${filter[1]}`;
  //     return;
  //   }
  //   filter[1].map((option) => {
  //     payload += `&${filter[0]}=${option}`;
  //   });
  // });
  console.log("FILTERS:::::", { filters, payload });
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/";
  const v7EndPoint =
    "https://mxihf1otk1.execute-api.ap-southeast-2.amazonaws.com/filter_api_v8";
  return await axios
    .post(v7EndPoint, {
      body: {
        ...filters,
        pageNumber: pageNo,
      },
    })
    .then((response) => {
      const data = JSON.parse(response.data.body);
      if (typeof data?.message === "string") {
        throw new Error(response.data.message);
      }
      const auditRows = data.message.audited_rows;
      console.log("All rows....", data);
      return auditRows;
    })
    .catch((error) => {
      console.error("Error in AuditRows Get request:", error);
      throw new Error(error.message);
    });
};

export const getauditData = async(
  load_id,
  priority_value,
  episode_identifier,
  want_flagged_episodes
) => {

    try {
      const response= await axios.post("https://wipdauwhq2.execute-api.ap-southeast-2.amazonaws.com/workflow_get_detailed_audit_data_v7/",{
        body: {
          load_id,
          priority_value,
          episode_identifier,
          want_flagged_episodes,
        },
      })
      const auditData = JSON.parse(response.data.body);
      console.log("All Audit details..", response.data);
      console.log("YAA",auditData.message);
      return auditData.message
    } catch (error) {
      console.error("Error in Auditdetail Get request:", error);
      throw new Error(error.message);
    }

  
};
// export const getauditData=debounce((...args)=>{
//   return debounceGetauditData(...args)
// })
export const getFilteredAuditData = async (filters) => {
  // const getapiEndpoint =
  //   "https://nw71l5ckl4.execute-api.ap-southeast-2.amazonaws.com/webwork_flow_filter_api/";
  // const v3EndPoint ="https://os3wl7iyn2.execute-api.ap-southeast-2.amazonaws.com/workflow_filter_api_v3/"
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/";
  const v7EndPoint =
    "https://mxihf1otk1.execute-api.ap-southeast-2.amazonaws.com/filter_api_v8";
  return await axios
    .post(v7EndPoint, {
      body: {
        ...filters,
      },
    })
    .then((response) => {
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }
      const filteredAuditData = response.data.body;
      return JSON.parse(filteredAuditData);
    })
    .catch((error) => {
      console.error("Error in FilteredAuditdetail  request:", error);
      throw new Error(error.message);
    });
};

export const getSearchData = async (searchData) => {
  const url =
    "https://erxx8ol3uf.execute-api.ap-southeast-2.amazonaws.com/work_flow_search_by_episode_identifier_v3";
  const params = {
    // episode_identifier: "I516064"
    episode_identifier: searchData,
  };
  return axios
    .get(url, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};
export const getSearchDataFlagged = async (searchData) => {
  const url =
    "https://xned2etrqe.execute-api.ap-southeast-2.amazonaws.com/search_by_episode_identifier_flagged_V1";
  const params = {
    // episode_identifier: "I516064"
    episode_identifier: searchData,
  };
  return axios
    .get(url, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};
export const getSearchRecommendations = async (identifier) => {
  const url =
    "https://tjz4mhdc33.execute-api.ap-southeast-2.amazonaws.com/web_work_flow_search_recomendation_episode_identifier_v2";
  try {
    const response = await axiosInstance.get(url, {
      params: { episode_identifier: identifier },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getSearchRecommendationsflagged = async (identifier) => {
  const url =
    "https://8k6qepz2ne.execute-api.ap-southeast-2.amazonaws.com/search_by_episode_identifier_recomendation_flagged_v1/";
  try {
    const response = await axios.get(url, {
      params: { episode_identifier: identifier },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const auditStatusUpdate = async (auditDetails) => {
  // const urlv3 =
  //   "https://svga801o97.execute-api.ap-southeast-2.amazonaws.com/work_flow_edit_audit_row_v3/";
  const urlv6 =
    "https://p5j2so4s03.execute-api.ap-southeast-2.amazonaws.com/work_flow_edit_audit_row_v6";
  const response = await axios.put(urlv6, { body: auditDetails });
  if (response.data.errorMessage) {
    throw new Error(response.data.errorMessage);
  }
  const message = JSON.parse(response.data.body);
  console.log(message, "res::::");
  return message;
};
export const getAuditFiles = async (pageNumber = 1) => {
  const url =
    "https://39w8002unj.execute-api.ap-southeast-2.amazonaws.com/get_audit_files_v2/";
  try {
    const response = await axiosInstance.get(url, {
      params: {
        pageNumber: pageNumber,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecords = async (pageNo) => {
  const url = `https://8yrsa3a6tf.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_v1/?pageNumber=${pageNo}`;
  try {
    const response = await axios.get(url);
    if (typeof response.data?.message === "string") {
      throw new Error(response.data.message);
    }
    return response.data.message.audited_rows;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecordsDetails = async (id) => {
  const url = `https://k5glluopp7.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_detailed_v1/?id=${id}`;
  try {
    const response = await axios.get(url);
    return response.data.meesage;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getfilteroptionsdata = async () => {
  const url =
    "https://0epfh04q11.execute-api.ap-southeast-2.amazonaws.com/work_flow_tool_get_filter_options/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchFlaggedEpisodes = async (searchFlaggedEpisodeQuery) => {
  try {
    const response = await fetch(
      `API_ENDPOINT?query=${searchFlaggedEpisodeQuery}`
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching flagged episodes", error);
    throw error; // rethrow the error to handle it in the component
  }
};
export const recall_episode = async (episodes) => {
  const payload = {
    episodes_to_unflag: episodes,
  };
  const endPointUrl =
    "https://mhkqbps7f8.execute-api.ap-southeast-2.amazonaws.com/unflag_episode_v1/";
  const response = await axios.post(endPointUrl, {
    body: payload,
  });
  console.log("M::::", response);
};
