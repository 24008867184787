import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuditDetails.css";
import leftarrow from "../../assests/left arrow.png";
import rightarrow from "../../assests/right arrow.png";
import {
  additionalDiagnosesFields,
  bottomSideSections,
  procedureCodesFields,
  topSideSections,
} from "./Constants";
import { PencilImg } from "../../assests/svgImages/Images";
import { extractLoadId, extractUserName, formatDate } from "../../utils/util";
import filtertick from "../../assests/filtertick.png";
import { auditStatusUpdate } from "../../api/Service";
import { Spinner } from "../../common/Helper";
import tick from "../../assests/tick-check.png";
import bell from "../../assests/newbell.png";
import folderimage from "../../assests/file_audit.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Version2Message } from "../../../chat-ai/components/chatpage/Message";
import axiosInstance from "../../../../axiosInterceptor";
// import { each } from "lodash";
const viewAllBtns = ["Procedure codes", "Additional diagnosis"];

const Section = ({ name, value, procedureCodes, additionalDiagnoses }) => {
  // console.log("new value.....", value, name);
  const [viewAllClick, setViewAllClick] = useState(false);
  const onViewAllClick = () => {
    setViewAllClick(true);
  };
  return (
    <div className="audit-values-row">
      <div className="row-name">{name}:</div>
      <div className="row-value">
        {name === "Episode failed multiple rules" ? (
          <div> {value ? "Yes" : "No"} </div>
        ) : (
          <>
            <>{value}</>
            &nbsp;
            {viewAllBtns.includes(name) && (
              <button className="view-all-codes-btn" onClick={onViewAllClick}>
                View All
              </button>
            )}
          </>
        )}
      </div>
      {
        <div
          className={`modal fade ${viewAllClick ? "show" : ""}`}
          style={{ display: viewAllClick ? "block" : "none" }}
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{name}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setViewAllClick(false)}
                ></button>
              </div>
              <div className="modal-body">
                {name === "Procedure codes" && (
                  <div>
                    {procedureCodes.map(({ key, value }, index) => (
                      <div key={index}>
                        {key}:{value === "nan" ? "no value" : value}
                      </div>
                    ))}
                  </div>
                )}
                {name === "Additional diagnosis" && (
                  <div>
                    {additionalDiagnoses.map(({ key, value }, index) => (
                      <div key={index}>
                        {key}:{value === "nan" ? "no value" : value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const NoRecordSelected = () => {
  return <div className="text-center ">No Record Selected</div>;
};
function AuditDetails({
  auditDetails,
  handlePreviousClick,
  handleNextClick,
  selectedIndex,
  length,
  setAuditDetails,
  getUpdatedAuditDetails,
  getAuditRowsData,
  setIsRowClicked,
  priorityVal5,
  flagStatus,
}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [inputValue, setInputValue] = useState(
    `The DRG is ${auditDetails.drg_code}. Generate a multiple-choice format CDQ to send the clinician to support ${auditDetails.audit_rule_short_description} that could result in higher complexity`
  );
  const [textValue, setTextValue] = useState(auditDetails.audit_comment || "");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [iscdqloading, setIscdqloading] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isEditloading, setIsEditloading] = useState(true);
  const [highestPriority, setHighestPriority] = useState([]);
  const [highestPriorityTasks, setHighestPriorityTasks] = useState([]);
  const procedureCodes = Array.from({ length: 50 }, (_, i) => {
    const key = `procedure_codes${i + 1}`;
    return { key, value: auditDetails[key] };
  });
  const additionalDiagnoses = Array.from({ length: 49 }, (_, i) => {
    const key = `additional_diagnoses${i + 1}`;
    return { key, value: auditDetails[key] };
  });
  const handleEditChange = (key, value, index, parentName) => {
    console.log("EDIT:::::", key, value, index, auditDetails);
    // if (parentName === "findings") {
      
    //   const details = { ...auditDetails };   
    //   // details[key] = value;
    //   // console.log("details ",details);
    //   // console.log("EDIT:::1", [details],details[key],value);
    //   console.log("new:::",...auditDetails)
    //   setAuditDetails(auditDetails);
    // } else if (parentName==="highestPriority"){
    //   const priorityTasks = [...highestPriorityTasks];
    //   const priorityDetails = {...highestPriorityTasks[0]}
    //   priorityDetails[key] = value;
    //   priorityTasks[0] = priorityDetails;
    //   setHighestPriorityTasks(priorityTasks);
    // }
    // else {
    //   setAuditDetails((prevDetails)=>{
    //     return {...prevDetails,[key]:value}
    //   });
      setAuditDetails((prevDetails) => {
        // If the key corresponds to an array of objects (like findings)
        if (parentName==='findings' && typeof index === "number") {
          const updatedFindings = [...prevDetails.findings];
          console.log(updatedFindings[index], "new");
          // Update the specific index with the new value
          updatedFindings[index] = {
            ...updatedFindings[index],
            [key]: value,
          };
          console.log("EDIT::::1", updatedFindings);

          return {
            ...prevDetails,
            findings:updatedFindings,
          };
        }
        else if (parentName==="highestPriority"){
            const priorityTasks = [...highestPriorityTasks];
            const priorityDetails = {...highestPriorityTasks[0]}
            priorityDetails[key] = value;
            priorityTasks[0] = priorityDetails;
            setHighestPriorityTasks(priorityTasks);
          }
        // For non-array key-value pairs
        return {
          ...prevDetails,
          [key]: value,
        };
      });
    
  };

  const handleEditSave = async () => {
    setIsEditloading(true);
    // const firstFinding =
    //   auditDetails.findings && auditDetails.findings[0]
    //     ? auditDetails.findings[0]
    //     : {};
    const firstFinding = auditDetails ? auditDetails?.findings[0] : {};
    // console.log("FIRST::",firstFinding.auditDetails?.findings.length,auditDetails?.findings[0])
  const {
    findings, // Destructure and omit findings
    ...otherAuditDetails // Collect all other properties
  } = auditDetails;
  const payload = {
    ...otherAuditDetails, // Spread other audit details excluding findings
    is_flagged: false, 
    predicted_drg_revenue: auditDetails.predicted_drg_revenue,// Set specific flag status
    audit_rule_short_description:
      firstFinding.audit_rule_short_description || "nan",
    audit_rule_long_description:
      firstFinding.audit_rule_long_description || "nan",
    audit_rule_category: firstFinding.audit_rule_category || "nan",
    priority_value: firstFinding.priority_value || "nan",
    audit_rule_ref_no: firstFinding.audit_rule_ref_no || "nan",
  };
    try {
      auditStatusUpdate(payload)
        .then(() => {
          setAuditDetails({});
          getAuditRowsData({});
        })
        .catch((err) => console.error("Status Update Error::", err.message))
        .finally(() => {
          setLoading(false);
          setIsRowClicked(false);
        });
    } catch (error) {
      console.error("Failed to update audit details:", error);
      setLoading(false);
    }
  };
  const handleStatusChange = async (e) => {
    // If the selected value is "Update status", set audit_status to "Failed"
    //setLoading(true);
    const selectedValue = e.target.value;
    let auditStatus = selectedValue === "" ? "Failed" : selectedValue;
    console.log("AUDITSTATUS:::",selectedValue)
      const firstFinding = auditDetails ? auditDetails?.findings[0] : {};
      // console.log("FIRST::",firstFinding.auditDetails?.findings.length,auditDetails?.findings[0])
    const {
      findings, // Destructure and omit findings
      ...otherAuditDetails // Collect all other properties
    } = auditDetails;
    const payload = {
      ...otherAuditDetails, // Spread other audit details excluding findings
      is_flagged: false, 
      audit_status: auditStatus,
      predicted_drg_revenue: auditDetails.predicted_drg_revenue,// Set specific flag status
      audit_rule_short_description:
        firstFinding.audit_rule_short_description || "nan",
      audit_rule_long_description:
        firstFinding.audit_rule_long_description || "nan",
      audit_rule_category: firstFinding.audit_rule_category || "nan",
      priority_value: firstFinding.priority_value || "nan",
      audit_rule_ref_no: firstFinding.audit_rule_ref_no || "nan",
    };
    try {
      const response = await auditStatusUpdate(payload);
        const {message}=response;
        console.log("FFF::",message);
        await getUpdatedAuditDetails(message.load_id,message.episode_identifier,message.priority_value,auditStatus);
    }catch(error){
      console.error("Status Update Error::", error.message)
    }finally{
      setLoading(false);
    }
  };
  const handleDontflagClick = () => {
    setLoading(true);
    const firstFinding =
      auditDetails.findings && auditDetails.findings[0]
        ? auditDetails.findings[0]
        : {};

    const {
      findings, // Destructure and omit findings
      ...otherAuditDetails // Collect all other properties
    } = auditDetails;
    const payload = {
      ...otherAuditDetails, // Spread other audit details excluding findings
      is_flagged: true, // Set specific flag status
      audit_rule_short_description:
        firstFinding.audit_rule_short_description || "nan",
      audit_rule_long_description:
        firstFinding.audit_rule_long_description || "nan",
      audit_rule_category: firstFinding.audit_rule_category || "nan",
      priority_value: firstFinding.priority_value || "nan",
      audit_rule_ref_no: firstFinding.audit_rule_ref_no || "nan",
    };
    try {
      auditStatusUpdate(payload)
        .then(() => {
          setAuditDetails({});
          getAuditRowsData({});
        })
        .catch((err) => console.error("Status Update Error::", err.message))
        .finally(() => {
          setLoading(false);
          setIsRowClicked(false);
        });
    } catch (error) {
      console.error("Failed to update audit details:", error);
      setLoading(false);
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleGenerateCDQ = async () => {
    setIscdqloading(true);
    try {
      const response = await axiosInstance.post(
        "https://l2mc3dvaa4.execute-api.us-west-2.amazonaws.com/lambdaAWSQV2/",
        {
          prompt: inputValue,
        }
      );
      const responseData = JSON.parse(response.data.body);
      const messageChunks = responseData.meesage;
      // console.log(messageChunks, "clinicaldata:::");
      setModalContent(messageChunks);
    } catch (error) {
      console.error("Error making POST request", error);
      setModalContent("An error occurred while making the request.");
    } finally {
      setIscdqloading(false);
      setModalVisible(true);
    }
  };
  console.log(auditDetails, "auditDetails::::");
  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (textValue.trim() === "") {
      alert("Please enter some text.");
      return;
    }
    setIsCommentsLoading(true);
    try {
      const response = await axios
        .post(
          "https://k4199a4e1l.execute-api.ap-southeast-2.amazonaws.com/add_audit_comment_v2/",
          {
            audit_comment: textValue,
            id: auditDetails.id,
            priority_value: auditDetails.priority_value,
          }
        )
        .then((res) => {
          const body = JSON.parse(res.data.body);
          const { id, priority_value } = body.message;
          // console.log("ID:::::", id,priority_value);
          getUpdatedAuditDetails(id, auditDetails.audit_status, priority_value);

          // console.log("Comment submitted  successfully!");
          alert("Comments submitted successfully!");
          setTextValue("");
        });

      setTextValue("");
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setIsCommentsLoading(false);
    }
  };
  //console.log('second',auditDetails?.findings);
  // const highestPriority = Math.max(
  //   ...auditDetails?.findings?.map((eachValue) => eachValue?.priority_value)
  // );
  // const highestPriorityTasks = auditDetails?.findings?.filter(
  //   (task) => task?.priority_value === highestPriority
  // );
  // console.log(highestPriorityTasks);
  // useEffect(() => {
  //   const unique = [...uniqueAuditDetails];
  //   if (auditDetails?.findings) {
  //     auditDetails?.findings.forEach((item) => {
  //       const exists = unique.find(
  //         (uniqueItem) =>
  //           uniqueItem.audit_rule_ref_no === item.audit_rule_ref_no
  //       );
  //       if (!exists) {
  //         unique.push(item);
  //       }
  //     });
  //     setUniqueAuditDetails(unique);
  //   }
  // }, [auditDetails]);

  useEffect(() => {
    if (auditDetails?.findings) {
      const vals = Math.max(
        ...auditDetails?.findings?.map((eachValue) => eachValue?.priority_value)
      );
      const tasks = auditDetails?.findings?.filter(
        (task) => task?.priority_value === vals
      );
      console.log("KK:::", vals, tasks);
      setHighestPriority(vals);
      setHighestPriorityTasks(tasks);
    }
  }, []);
  //console.log("DETAILS::::", uniqueAuditDetails, auditDetails,highestPriorityTasks);
  return (
    <div className="audit-details">
      <div className="webworkflow-crumb-wrapper">
        <span
          onClick={() => setIsRowClicked(false)}
          className="workflow-bread-crumb-first"
        >
          Workflow Companion
        </span>{" "}
        {">"}{" "}
        {highestPriorityTasks.length && (
          <span className="workflow-bread-crumb-second">
            {highestPriorityTasks[0].audit_rule_short_description}
          </span>
        )}
      </div>
      <div>
        <h1 className="audit-deatils-header-text">
          {highestPriorityTasks.length && (
            <>{highestPriorityTasks[0].audit_rule_short_description}</>
          )}
        </h1>
      </div>
      <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
        <div className="status-container">
          <label className="dropdown-label" htmlFor="dropdown">
            Status:
          </label>{" "}
          <select
            id="dropdown"
            defaultValue={auditDetails.audit_status}
            onChange={handleStatusChange}
            className="status-dropdown"
            disabled={priorityVal5}
          >
            <option value="">Update status</option>
            <option value="Flagged for Review">Flagged for Review</option>
            <option value="Reviewed,no change">Reviewed, no change</option>
            <option value="Reviewed,query required">
              Reviewed, query required
            </option>
            <option value="Reviewed,query sent">Reviewed, query sent</option>
            <option value="Reviewed,recoded">Reviewed, recoded</option>
            <option value="Reviewed,rebilled">Reviewed, rebilled</option>
            <option value="Reviewed,reclaimed">Reviewed, reclaimed</option>
          </select>
        </div>
        {!flagStatus && (
          <button
            className="donot-flag"
            onClick={handleDontflagClick}
            disabled={priorityVal5}
          >
            {loading ? (
              <div className="d-flex">
                Do not flag this episode anymore
                <Spinner className="donot-flag-loader" />
              </div>
            ) : (
              <div>
                Do not flag this episode anymore <img src={tick} alt="tick" />
              </div>
            )}
          </button>
        )}
      </div>
      {auditDetails.name ? (
        <div className="audit-main">
          <div className="audit-details-header">
            {/* <FilesImg /> */}
            <div className="folder-image-wrapper">
              <img className="folder-image" src={folderimage} alt="folderimg" />
            </div>
            <p className="user-name">
              {extractUserName(auditDetails.name).replace(/[\d-]+.*$/, "")}{" "}
            </p>
            <p className="addmission-date">
              Admission Date: {auditDetails.admission_date}
            </p>
            <p className="seperation-date">
              Separation Date: {auditDetails.seperation_date}
            </p>
            <p className="Priority-value">
              Priority value:{" "}
              {highestPriorityTasks.length && (
                <div
                  className={`audit-count p${highestPriorityTasks[0].priority_value}`}
                >
                  P{highestPriorityTasks[0].priority_value}
                </div>
              )}{" "}
            </p>
            <div className="insurer-identifier-parent">
              Insurer identifier:{" "}
              <div className="insurer-identifier">
                {auditDetails.insurer_identifier}
              </div>
            </div>
          </div>

          <div className="status-container-value-spacing">
            <h5>
              {highestPriorityTasks.length && (
                <>{highestPriorityTasks[0].audit_rule_short_description}</>
              )}
              <button
                className="editAuditDetails-btn"
                onClick={toggleModal}
                style={{ border: "none", background: "white", color: "black" }}
              >
                <PencilImg />
              </button>
              {/* Audit details edit Modal */}
              <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
                tabIndex="-1"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Audit Details</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={toggleModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="auditrule-discription-modal">
                        {highestPriorityTasks.length && (
                          <>
                            <input
                              value={
                                highestPriorityTasks[0]
                                  .audit_rule_short_description
                              }
                              disabled
                              onChange={(e) =>
                                handleEditChange(
                                  "audit_rule_short_description",
                                  e.target.value,
                                  null,
                                  'highestPriority'
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                      <div className="auditrule-discription-modal">
                        {highestPriorityTasks.length && (
                          <input
                            value={
                              highestPriorityTasks[0]
                                .audit_rule_long_description
                            }
                            disabled
                            onChange={(e) =>
                              handleEditChange(
                                "audit_rule_long_description",
                                e.target.value,
                                null,
                                "highestPriority"
                              )
                            }
                          />
                        )}
                      </div>

                      <div className="audit-description">
                        <div className="audit-description-top">
                          {topSideSections.map((section, i) => (
                            <React.Fragment key={i}>
                              <div>{section.title}</div>
                              <input
                                className="audit-description-top-input"
                                value={auditDetails[section.key]}
                                onChange={(e) =>
                                  handleEditChange(section.key, e.target.value)
                                }
                                disabled={
                                  section.key === "episode_identifier" ||
                                  section.key === "medical_record_number"
                                }
                              />
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="audit-description-bottom">
                          {auditDetails?.findings?.map((finding, index) => {
                            return (
                              <div key={`finding-${index}`}>
                                {bottomSideSections.map((section) => (
                                  <div key={`section-${section.key}-${index}`}>
                                    <div>{section.title}</div>
                                    <input
                                      className="audit-description-bottom-input"
                                      value={
                                        finding[section.key]||''
                                      }
                                      onChange={(e) =>
                                        handleEditChange(
                                          section.key,
                                          e.target.value,
                                          index,
                                          "findings"
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="procedure-codes-wrapper">
                        {procedureCodesFields.map((section, i) => (
                          <div className="procedure-code-field" key={i}>
                            <div>{section.title}</div>
                            <input
                              className="procedure-code-field-input"
                              value={auditDetails[section.key]}
                              onChange={(e) =>
                                handleEditChange(section.key, e.target.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                      <div className="additional-diagnoses-wrapper">
                        {additionalDiagnosesFields.map((section, i) => (
                          <div className="additional-diagnoses-field" key={i}>
                            <div>{section.title}</div>
                            <input
                              className="additional-diagnoses-field-input"
                              value={auditDetails[section.key]}
                              onChange={(e) =>
                                handleEditChange(section.key, e.target.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleModal}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleEditSave}
                      >
                        <span>
                          <img src={filtertick} alt="Filter Tick" />
                        </span>{" "}
                        {isEditloading ? "Save" : "Saving"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </h5>
            <div className="charge-discription-wrapper">
              <p className="charge-discription">
                {auditDetails?.findings.length && (
                  <>{auditDetails?.findings[0].audit_rule_category}</>
                )}
              </p>
              {/* <PencilImg /> */}
            </div>
            <div className="missing-link">
              <img src={bell} alt="bell" />
              {auditDetails?.findings.length && (
                <>{auditDetails?.findings[0].audit_rule_category}</>
              )}
            </div>
            <div className="audit-description">
              <div className="audit-description-top">
                {topSideSections.map((section) => (
                  <Section
                    key={section.key}
                    name={section.title}
                    value={auditDetails[section.key]}
                    procedureCodes={procedureCodes}
                    additionalDiagnoses={additionalDiagnoses}
                  />
                ))}
              </div>
              <div className="audit-description-bottom">
                {auditDetails?.findings?.map((finding, index) => {
                  console.log(auditDetails,'GET...')
                  return (
                    <>
                      {" "}
                      {bottomSideSections.map((section) => (
                        <Section
                          key={section.key}
                          name={section.title}
                          value={
                            auditDetails[section.key] ||
                            finding[section.key]
                          }
                          procedureCodes={procedureCodes}
                          additionalDiagnoses={additionalDiagnoses}
                        />
                      ))}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className="type-info-wrapper">
                <label>Clinician Query:</label>
                <div className="d-flex flex-column">
                  <input
                    className="type-info"
                    type="text"
                    placeholder="Type in info about Clinician Query"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <button
                    className="generate-cdq-btn"
                    onClick={handleGenerateCDQ}
                    disabled={iscdqloading || !inputValue.length}
                  >
                    {iscdqloading ? "Generating..." : "Generate CDQ"}
                  </button>
                </div>
              </div>
              <div className="comments-text-wrapper">
                <label>Audit Comments:</label>
                <textarea
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                  placeholder={"Type your comments here..."}
                  style={{
                    padding: "8px",
                    width: "100%",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                />
                <button
                  className="audit-comment-btn"
                  onClick={handleCommentSubmit}
                >
                  {isCommentsLoading ? "Adding Comment..." : "Add Comment"}
                </button>
              </div>

              <div
                className={`modal fade ${modalVisible ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: modalVisible ? "block" : "none" }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title cdq-header-text">
                        CDQ Response
                      </h5>
                    </div>
                    <div className="modal-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <div style={{ whiteSpace: "pre-line" }}>
                          {modalContent.error ? (
                            <p>{modalContent.error}</p>
                          ) : (
                            <Version2Message
                              message={{ answer: modalContent }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setModalVisible(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="toggle-next-prev">
              <div>
                Row {selectedIndex + 1} of <strong>{length}</strong>
              </div>
              <div className="previous-next-wrapper">
                {selectedIndex + 1 !== 1 && (
                  <button
                    className="previous-btn"
                    disabled={selectedIndex + 1 === 1}
                    onClick={handlePreviousClick}
                  >
                    <img src={leftarrow} alt="leftarrow" /> Previous
                  </button>
                )}
                <button
                  className="next-btn"
                  disabled={selectedIndex + 1 === length}
                  onClick={handleNextClick}
                >
                  Next <img src={rightarrow} alt="rightarrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoRecordSelected />
      )}
    </div>
  );
}

export default AuditDetails;
