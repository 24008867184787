import React, { useCallback, useEffect, useState } from "react";
// import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./Page.css";
import AuditDetails from "../auditdetails/AuditDetails";
import AuditRows from "../audits/AuditRows";
import { initilaDetailsObj } from "../../utils/Constants";
import {
  getAuditRows,
  getFilteredAuditData,
  getPassedRecords,
  getPassedRecordsDetails,
  getSearchDataFlagged,
  getSearchRecommendationsflagged,
  getauditData,
  recall_episode,
} from "../../api/Service";
import { Spinner } from "../../common/Helper";
import { useChatContext } from "../../../../context";
import { Button } from "react-bootstrap";
import _ from "lodash";

function Page({ isRowClicked, setIsRowClicked }) {
  const { webWorkFlow } = useChatContext();
  const { auditDetails, setAuditDetails } = webWorkFlow;
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [auditRows, setAuditRows] = useState([]);
  const [isRowsLoading, setIsRowsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({
    insurer_identifier: [],
    priority_value: [],
    status: [],
    file_type: [],
    load_id: [],
    want_flagged_episodes:false
  });
  const [isP5Clicked, setIsP5Clicked] = useState(false);
  const [priorityVal5, setPriorityVal5] = useState(false);
  const [isRecallClicked, setIsRecallClicked] = useState(false);
  const [searchIdentifierText, setSearchIdentifierText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [flagStatus, setflagStatus] = useState(false);
  const [isRecommendationsLoading, setIsRecommendationsLoading] =
    useState(false);
  const [recommendations, setRecommendations] = useState([]);
  console.log(isP5Clicked, "isP5Clicked:::");

  const getAuditDetailsById = async (
    load_id,
    priority_value,
    episode_identifier,
    want_flagged_episodes
  ) => {
    setIsDetailsLoading(true);
    try {
      const data=await getauditData(
        load_id,
        priority_value,
        episode_identifier,
        (want_flagged_episodes = flagStatus ? true : false)
      )
      console.log("DATA::::", data);
        const details = data.audited_rows[0];
        console.log("Details...", details);
        setAuditDetails({ ...details, load_id });
        return { ...details, load_id };
      
    } catch (error) {
      console.error(error.message);
      throw new Error(error.message);
      
    }
    finally{
      setIsDetailsLoading(false)
    }
    // getauditData(
    //   load_id,
    //   priority_value,
    //   episode_identifier,
    //   (want_flagged_episodes = flagStatus ? true : false)
    // )
    //   .then((data) => {
    //     console.log("DATA::::", data);
    //     const details = data.audited_rows[0];
    //     console.log("Details...", details);
    //     setAuditDetails({ ...details, load_id });
    //     return { ...details, load_id };
    //   })
    //   .catch((err) => {
    //     console.error(err.message);
    //     throw new Error(err.message);
    //   })
    //   .finally(() => {
    //     setIsDetailsLoading(false);
    //   });
  };
  const handleRowClick = (row) => {
    console.log("ROW::::::", row.name);
    getAuditDetailsById(
      row?.name,
      row?.priority_value[0],
      row?.episode_identifier
    );
    const index = auditRows.findIndex((data) => data.name === row.name);
    setSelectedIndex(index);
    setIsRowClicked(true);
    setPriorityVal5(row?.priority_value === 5 ? true : false);
  };
  const updatedAuditRows = (rows) => {
    console.log("VAAA:::",rows,auditRows)
    setAuditRows(rows);
  };
  const getAuditRowsByFilter = async (filters) => {
    try {
      updatedAuditRows([]);
      setIsRowsLoading(true);
      setAuditDetails({});
      setAppliedFilters({ ...appliedFilters, ...filters });
      setHasMore(true);
      setIsP5Clicked(false);
      const appliedfilter = { ...appliedFilters, ...filters, pageNumber: 1 };
      const data = await getFilteredAuditData(appliedfilter);
      if (data.message?.audited_rows?.length) {
        setHasMore(true);
      }
      updatedAuditRows(data.message.audited_rows);
      setIsRowsLoading(false);
    } catch (err) {
      setIsRowsLoading(false);
      setHasMore(false);
    }
  };
  const handleRefreshClick = () => {
    setAuditDetails({});
    getAuditRowsData();
    window.location.reload();
  };
  // const getAuditRowsData = (page = 1) => {
  //   setIsRowsLoading(true);
  //   getAuditRows(page ? page : 1, appliedFilters)
  //     .then((data) => {
  //       if (data.length === 0) {
  //         setHasMore(false);
  //       }
  //       setAuditRows([...auditRows, ...data]);
  //       setPage(page + 1);
  //     })
  //     .catch((err) => {
  //       console.error("AUDIROW_ERR::", err);
  //       setIsRowsLoading(false);
  //       setHasMore(false);
  //     })
  //     .finally(() => {
  //       setIsRowsLoading(false);
  //     });
  // };
  const getAuditRowsData = (page = 1, updatedFilters = undefined) => {
    setIsRowsLoading(true);
    const filters = updatedFilters ? updatedFilters : appliedFilters;
    getAuditRows(page ? page : 1, filters)
      .then((data) => {
        if (data.length === 0) {
          setHasMore(false);
        }
        if (updatedFilters?.want_flagged_episodes) {
          console.log("HH:::");
          setAuditRows([...data]);
          setTableData([...data]);
          setPage(1 + 1);
        } else {
          console.log("HH:::1");

          setAuditRows([...auditRows, ...data]);
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.error("AUDIROW_ERR::", err);
        setIsRowsLoading(false);
        setHasMore(false);
      })
      .finally(() => {
        setIsRowsLoading(false);
      });
  };

  const handlePreviousClick = () => {
    const index = selectedIndex - 1;
    getAuditDetailsById(
      auditRows[index].name,
      auditRows[index].priority_value[0],
      auditRows[index].episode_identifier
    );
    setSelectedIndex(index);
  };
  const handleNextClick = () => {
    const index = selectedIndex + 1;
    getAuditDetailsById(
      auditRows[index].name,
      auditRows[index].priority_value[0],
      auditRows[index].episode_identifier
    );
    setSelectedIndex(index);
  };
  const handleAuditDataUpdate = (id,episode_identifier,priority_value,status) => {
    getAuditDetailsById(id, priority_value,episode_identifier).then(() => {
      //getting
      const updatedRow = auditRows[selectedIndex];
      // updating
      updatedRow.id = id;
      updatedRow.audit_status = status;
      //replacing in the same index
      auditRows[selectedIndex] = updatedRow;
    });
  };
  // const handleP5PriorityClick = (page) => {
  //   getPassedRecords(page)
  //     .then((data) => {
  //       updatedAuditRows(data);
  //       setIsP5Clicked(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching passed records:", error);
  //     });
  // };
  useEffect(() => {
    getAuditRowsData();
    window.scrollTo(0, 0);
  }, []);

  const getRecommendationVals = useCallback(
    _.debounce((identifier) => {
      setIsRecommendationsLoading(true);
      getSearchRecommendationsflagged(identifier)
        .then((res) => {
          console.log("RES:::", res);
          const { audited_rows } = res.meesage;
          setRecommendations(audited_rows);
        })
        .catch((err) =>
          console.error("Search Recommendations Error::", err.message)
        )
        .finally(() => setIsRecommendationsLoading(false));
    }, 500),
    []
  );

  const handleRecallClick = async () => {
    const status= !flagStatus
    setflagStatus(status);
    setIsRecallClicked(!isRecallClicked);
    setSearchIdentifierText("");
    setAllChecked(false);
    setCheckedIds([]);
    setTableData([]);
    const updatedFilters = { ...appliedFilters, want_flagged_episodes: status };
    console.log('UPDATED:::',updatedFilters)
    setAppliedFilters(updatedFilters);
    await getAuditRowsData(1, updatedFilters);
  };
  const handleIdentifierSearch = async (e) => {
    const { value } = e.target;
    setSearchIdentifierText(e.target.value);
    try {
      if (value) {
        await getRecommendationVals(value);
      }
    } catch (err) {
      console.log("IDERR::", err);
    }
  };
  const getSearchResults = (searchData) => {
    console.log("ENTER::::",searchData)
    setIsRowsLoading(true);
    getSearchDataFlagged(searchData)
      .then((response) => {
        //updatedAuditRows(response.meesage.audited_rows);
        setAuditDetails({});
        setSearchIdentifierText("");
        setTableData(response.meesage.audited_rows)
        setHasMore(false);
        setRecommendations([]);
      })
      .catch((err) => console.error("Search Results Error::", err.message))
      .finally(() => {
        setIsRowsLoading(false);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getSearchResults(searchIdentifierText);
    }
  };

  const handleSearchRecommendClick = (rowId) => {
    setSearchIdentifierText(rowId);
    getSearchResults(rowId);
  };
  const handleCheckboxChange = (e, name, episode_identifier) => {
    e.stopPropagation();
    let updatedIds = [...checkedIds];
    const alreadyPresent = updatedIds.filter((obj) => obj.episode_identifier === episode_identifier);
    console.log("CHANGE::::", alreadyPresent);
    if (alreadyPresent.length) {
      console.log("CHANGE::::1", alreadyPresent);

      updatedIds = updatedIds.filter((val) => val.episode_identifier !== episode_identifier);
    } else {
      console.log("CHANGE::::2", alreadyPresent);

      updatedIds.push({ load_id: name, episode_identifier });
    }
    setCheckedIds(updatedIds);
  };
  const handleAllCheckboxes = () => {
    if (allChecked) {
      setAllChecked(false);
      setCheckedIds([]);
    } else {
      const allIds = [];
      tableData.map((row) => {
        allIds.push({
          load_id: row.name,
          episode_identifier: row.episode_identifier,
        });
      });
      console.log("ALL::::", allIds);
      setCheckedIds(allIds);
      setAllChecked(true);
    }
  };
  const handleUnflagClick = async (e) => {
    e.preventDefault();
    console.log("UNFLAG::::", checkedIds,auditRows);
    setflagStatus(false);
    await recall_episode(checkedIds);
    setTableData([]);
    const filters = {...appliedFilters,want_flagged_episodes:false}
    setAppliedFilters(filters);
    console.log('UPDATED:::1',filters)
    getAuditRowsData(1,filters);
    setAllChecked(false);
    setCheckedIds([]);
    setIsRecallClicked(false);
  };
 
  useEffect(() => {
    if (auditRows) {
      console.log("EFFECT::::", auditRows);
      setTableData(auditRows);
    }
  }, [auditRows]);
  useEffect(() => {
    if (checkedIds.length && checkedIds.length === tableData.length) {
      setAllChecked(true);
      console.log("Yes::::")
    } else {
      setAllChecked(false);
      console.log("No:::::")
    }
  }, [checkedIds]);
  console.log(auditRows,tableData, "ROWS::::");

  return (
    <div className="main-page h-100">
      {isRowClicked ? (
        <>
          {isDetailsLoading ? (
            <Spinner />
          ) : (
            <AuditDetails
              selectedIndex={selectedIndex}
              length={auditRows.length}
              auditDetails={auditDetails}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              setAuditDetails={setAuditDetails}
              getUpdatedAuditDetails={handleAuditDataUpdate}
              getAuditRowsData={getAuditRowsByFilter}
              setIsRowClicked={setIsRowClicked}
              priorityVal5={priorityVal5}
              flagStatus={flagStatus}
            />
          )}
        </>
      ) : (
        <div className="audit-content">
          <Navbar
            updatedAuditRows={updatedAuditRows}
            handleRefreshClick={handleRefreshClick}
            setAuditDetails={setAuditDetails}
            setIsRowsLoading={setIsRowsLoading}
            getAuditRowsByFilter={getAuditRowsByFilter}
            setHasMore={setHasMore}
            isRecallClicked={isRecallClicked}
            handleRecallClick={handleRecallClick}
            flagStatus={flagStatus}
          />
          {isRecallClicked && (
            <div className={"recall-unflag-wrapper"}>
              <div className="recall-search">
                <input
                  type="search"
                  placeholder="Search by Patient Episode Identifier"
                  onKeyDown={handleKeyPress}
                  value={searchIdentifierText}
                  onChange={handleIdentifierSearch}
                />
                <div className="search-identifier-recommendations">
                  {isRecommendationsLoading ? (
                    <div className="loader">
                      <Spinner />
                    </div>
                  ) : (
                    recommendations?.map((item, idx) => (
                      
                      <li
                        key={idx}
                        onClick={() =>
                          handleSearchRecommendClick(item.episode_identifier)
                        }
                        className="search-identifier-recommendation"
                      >
                        {item.episode_identifier}
                      </li>
                    ))
                  )}
                </div>
              </div>
              <div>
                <Button onClick={handleUnflagClick}>Recall An Episode</Button>
              </div>
            </div>
          )}
          <div className="audits w-100 h-100">
            <AuditRows
              handleRowClick={handleRowClick}
              auditRows={tableData}
              isRowsLoading={isRowsLoading}
              selectedIndex={selectedIndex}
              hasMore={hasMore}
              loadMore={() => getAuditRowsData(page)}
              handleCheckboxChange={handleCheckboxChange}
              checkedIds={checkedIds}
              handleAllCheckboxes={handleAllCheckboxes}
              allChecked={allChecked}
            />
            {/* <div className="sidebar">

              <Sidebar
                updatedAuditRows={updatedAuditRows}
                setAuditDetails={setAuditDetails}
                getAuditRowsByFilter={getAuditRowsByFilter}
                handleP5PriorityClick={() => handleP5PriorityClick(1)}
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;